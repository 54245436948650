import React from 'react';
import Container from './Container';
import Hero from './Hero';

function App() {
  return (
    <Container>
      <a href="https://catar-records.bandcamp.com/">
        <Hero image="./catar.jpg" />
      </a>
    </Container>
  );
}

export default App;

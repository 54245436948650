import styled, { css } from 'styled-components';

interface HeroProps {
  readonly image?: string;
};

const Hero = styled.div<HeroProps>`
  ${props => props.image && css`
    background-image: url(${props.image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `}
  height: 100vh;
  width: 100%;
`

export default Hero;
